<template>
  <v-card tile :loading="loading">
    <QuickMappingDialog />
    <v-row class="mr-3 mb-2 pt-5">
      <v-spacer></v-spacer>
      <v-text-field
        v-model="valueSearch"
        append-icon="mdi-magnify"
        :label="$t('form.search')"
        single-line
        flat
        hide-details
        style="max-width: 400px"
        class="pr-4"
        @input="searchItems"
      ></v-text-field>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :page="itemsPage"
      :server-items-length="totalItems"
      :sort-by="sortByField"
      :sort-desc="sortDesc"
      must-sort
      @update:page="updatePage($event)"
      @update:items-per-page="updateItemsPerPage($event)"
      @update:sort-by="updateSortBy($event)"
      @update:sort-desc="updateSortOrder($event)"
      :footer-props="footerProps"
      :loading-text="$t('table.loading', { prop: $t('values.ignored.title') })"
      :no-data-text="$t('table.no_data', { prop: $t('values.ignored.title') })"
    >
      <template v-slot:item.modifiedDt="{ item }">
        {{ new Date(item.modifiedDt).toLocaleString("nl-NL") }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="item.ignored === true"
          @click.stop="removeIgnoreMapping(item)"
          :title="$t('value.table.ignore_remove')"
        >
          mdi-delete-outline
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import QuickMappingDialog from "@/components/attribute/mappingCreation/QuickMappingDialog";

export default {
  name: "InputValuesIgnored",
  components: {
    QuickMappingDialog
  },
  data: vm => ({
    ignoredDialog: false,
    headers: [
      {
        text: vm.$tc("value.table.value"),
        value: "content"
      },
      // {
      //   text: vm.$t("value.table.modified_by"),
      //   value: "modifiedBy"
      // },
      {
        text: vm.$t("value.table.modified_dt"),
        value: "modifiedDt"
      },
      {
        text: vm.$t("value.table.actions"),
        value: "actions",
        sortable: false
      }
    ]
  }),
  computed: {
    ...mapState("attributeOverview", ["attribute"]),
    ...mapState("attributeOverview/inputValueIgnoredOverview", [
      "loading",
      "items",
      "totalItems",
      "itemsPage",
      "itemsPerPage",
      "sortByField",
      "sortDesc",
      "footerProps"
    ]),
    valueSearch: {
      get() {
        return this.$store.state.attributeOverview.inputValueIgnoredOverview
          .valueSearch;
      },
      set(value) {
        this.setValueSearch(value);
      }
    }
  },
  mounted() {
    this.$store.state.attributeOverview.inputValueIgnoredOverview.sortByField =
      "name";
    this.$store.state.attributeOverview.inputValueIgnoredOverview.sortDesc = false;
    this.$store.state.attributeOverview.inputValueIgnoredOverview.itemsPerPage = 25;
    this.searchItems();
  },
  methods: {
    ...mapActions("modelDialog", ["showModelDialog"]),
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("attributeOverview/inputValueIgnoredOverview", [
      "searchItems",
      "updatePage",
      "updateItemsPerPage",
      "updateSortBy",
      "updateSortOrder",
      "setValueSearch",
      "setValue",
      "editValue"
    ]),
    async removeIgnoreMapping(item) {
      item.ignored = false;
      this.editValue(item)
        .then(() => {
          this.showSnack({
            text: this.$t("value.actions.remove-ignore_successful", {
              value: item.content
            }),
            color: "success"
          });
        })
        .catch(errorMessage => {
          this.showSnack({ text: errorMessage, color: "error" });
        });
    }
  }
};
</script>
