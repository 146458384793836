<script>
// used to save variables to use inside the child elements
// f.e. you want to re-use a return value from a method and don't want to get it 3 times
// then use this variable pass to save the value in a temp variable
/**
 * Used to save variables to use inside the child elements
 * f.e. you want to re-use a return value from a method and don't want to get it 3 times
 * then use this variable pass to save the value in a temp variable
 * 
 * Usage example. Here you only need to execute getMappedOption once, to use it twice:
 * <template v-slot:[`item.mapped`]="{ item }">
        <VariablePass
          :mappedOption="getMappedOption(item)"
          v-slot="{ mappedOption }"
        >
          <v-icon :color="mappedOption.color">{{ mappedOption.icon }}</v-icon>
        </VariablePass>
      </template>
 */
export default {
  name: "VariablePass",
  render() {
    return this.$scopedSlots.default(this.$attrs);
  }
};
</script>
