var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-toolbar',{staticClass:"header",attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("attribute.overview")))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-switch',{staticClass:"pr-4",attrs:{"color":"blue","single-line":"","hide-details":""},on:{"change":_vm.searchItems},model:{value:(_vm.onlyShowValuesToMap),callback:function ($$v) {_vm.onlyShowValuesToMap=$$v},expression:"onlyShowValuesToMap"}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("attributes.only_show_values_to_map")))])]),_c('v-text-field',{staticStyle:{"max-width":"210px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('form.search'),"single-line":"","flat":"","hide-details":"","clearable":""},on:{"input":_vm.searchAttribute,"click:clear":_vm.clearSearchAttribute},model:{value:(_vm.attributeSearch),callback:function ($$v) {_vm.attributeSearch=$$v},expression:"attributeSearch"}})],1),_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.itemsPage,"server-items-length":_vm.totalItems,"sort-by":_vm.sortByField,"sort-desc":_vm.sortDesc,"must-sort":"","footer-props":_vm.footerProps,"item-key":"id","loading-text":_vm.$t('table.loading', { prop: _vm.$t('attribute.title') }),"no-data-text":_vm.$t('table.no_data', { prop: _vm.$t('attribute.title') }),"single-select":""},on:{"update:page":function($event){return _vm.updatePage($event)},"update:items-per-page":function($event){return _vm.updateItemsPerPage($event)},"update:sort-by":function($event){return _vm.updateSortBy($event)},"update:sort-desc":function($event){return _vm.updateSortOrder($event)},"click:row":_vm.selectAll},scopedSlots:_vm._u([{key:"item.totalUnmappedInputValues",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{key:item.value,attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.totalInputValues)+" ("+_vm._s(item.totalUnmappedInputValues)+") ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("attribute.statistics-alt", { total: item.totalInputValues, unmapped: item.totalUnmappedInputValues })))])])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }