<template>
  <v-card tile :loading="loading">
    <v-row class="mr-3 mb-2 pt-5">
      <v-spacer></v-spacer>
      <v-text-field
        v-model="valueSearch"
        append-icon="mdi-magnify"
        :label="$t('form.search')"
        single-line
        flat
        hide-details
        style="max-width: 400px"
        class="pr-4"
        @input="searchItems"
      ></v-text-field>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :page="itemsPage"
      :server-items-length="totalItems"
      :sort-by="sortByField"
      :sort-desc="sortDesc"
      must-sort
      @update:page="updatePage($event)"
      @update:items-per-page="updateItemsPerPage($event)"
      @update:sort-by="updateSortBy($event)"
      @update:sort-desc="updateSortOrder($event)"
      :footer-props="footerProps"
      :loading-text="$t('table.loading', { prop: $t('mappings.title') })"
      :no-data-text="$t('table.no_data', { prop: $t('mappings.title') })"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          :aria-label="$t('value.quickmap-button')"
          @click="createQuickMapping(item)"
          :title="$t('value.quickmap-button')"
          v-if="item.mapped !== true"
        >
          mdi-tag-plus-outline
        </v-icon>
        <v-icon
          :aria-label="$t('mappings.delete-button')"
          @click="deleteMappingConfirm(item)"
          :title="$t('mappings.delete-button')"
        >
          mdi-delete-outline
        </v-icon>
      </template>
    </v-data-table>
    <MappingCreationDialog />
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MappingCreationDialog from "@/components/attribute/mappingCreation/MappingCreationDialog";

export default {
  name: "MappingResults",
  components: {
    MappingCreationDialog
  },
  data: vm => ({
    headers: [
      {
        text: vm.$tc("mappings.table.mapping-name"),
        value: "mapping.name"
      },
      {
        text: vm.$tc("mappings.table.input-value"),
        value: "inputValue.content"
      },
      {
        text: vm.$tc("mappings.table.output-value"),
        value: "outputValue.content"
      },
      {
        text: vm.$tc("mappings.table.actions"),
        value: "actions"
      }
    ]
  }),
  computed: {
    ...mapState("attributeOverview", ["attribute"]),
    ...mapState("mappingOverview/mappingResultOverview", [
      "loading",
      "items",
      "totalItems",
      "itemsPage",
      "itemsPerPage",
      "sortByField",
      "sortDesc",
      "footerProps"
    ]),
    valueSearch: {
      get() {
        return this.$store.state.mappingOverview.mappingResultOverview
          .valueSearch;
      },
      set(value) {
        this.setValueSearch(value);
      }
    }
  },
  methods: {
    ...mapActions("modelDialog", ["showModelDialog"]),
    ...mapActions("mappingOverview/mappingResultOverview", [
      "searchItems",
      "updatePage",
      "updateItemsPerPage",
      "updateSortBy",
      "updateSortOrder",
      "setValueSearch",
      "deleteMappingResult"
    ]),
    ...mapActions("attributeOverview/inputValueOverview", ["fetchValue"]),
    ...mapActions("attributeOverview/mappingCreationOverview", [
      "setMapping",
      "clearMapping"
    ]),
    ...mapActions("snackbar", ["showSnack"]),
    createQuickMapping(item) {
      this.fetchValue(item.inputValue["@id"]);
      this.showModelDialog("QuickMappingDialog");
    },
    getMappedOption(item) {
      return this.mappedOptions.find(x => x.value == item.mapped);
    },
    openNewMappingDialog() {
      this.clearMapping(this.attribute);
      this.showModelDialog("MappingCreationDialog");
    },
    openUpdateMappingDialog(mapping) {
      this.setMapping(mapping);
      this.showModelDialog("MappingCreationDialog");
    },
    async deleteMappingConfirm(item) {
      const confirm = await this.$confirm(
        this.$t("mappings.delete-confirm", {
          mapping: item.mapping.name
        }),
        { title: this.$t("core.warning") }
      );

      if (confirm) {
        this.deleteMappingResult(item)
          .then(() => {
            this.showSnack({
              text: this.$t("mappings.deleted-mapping"),
              color: "success"
            });
          })
          .catch(err => {
            this.showSnack({ text: err, color: "error" });
          });
      }
    }
  }
};
</script>
