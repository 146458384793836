<template>
  <v-container fluid>
    <Header>
      <template v-slot:title>{{ $t("attribute.title") }}</template>
      <template v-slot:subtitle>{{ $t("attribute.subtitle") }}</template>
    </Header>

    <v-row>
      <v-col col="4">
        <Attributes />
      </v-col>

      <v-col cols="8">
        <AttributeInfo />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Attributes from "@/components/attribute/Attributes";
import AttributeInfo from "@/components/attribute/AttributeInfo";
import { mapActions } from "vuex";

export default {
  name: "AttributeOverview",
  components: { Attributes, AttributeInfo },
  methods: {
    ...mapActions("attributeOverview", ["selectAttribute"])
  }
};
</script>
