<template>
  <v-autocomplete
    v-if="attribute.alias"
    v-model="outputValue"
    :items="items"
    item-text="content"
    :label="$t('mapping-creation.select-output-value')"
    :loading="loading"
    return-object
  ></v-autocomplete>
  <v-text-field
    v-else
    :label="$t('mapping-creation.write-output-value')"
    @blur="createNewValueObject"
  ></v-text-field>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "MappingOutputValues",
  data: () => ({
    textValue: null
  }),
  computed: {
    ...mapState("attributeOverview", ["attribute"]),
    ...mapState(
      "attributeOverview/mappingCreationOverview/mappingOutputValueOverview",
      ["loading", "items"]
    ),
    outputValue: {
      get() {
        return this.$store.state.attributeOverview.mappingCreationOverview
          .mapping.outputValue;
      },
      set(value) {
        this.setOutputValue(value);
      }
    }
  },
  methods: {
    ...mapActions(
      "attributeOverview/mappingCreationOverview/mappingOutputValueOverview",
      ["searchItems"]
    ),
    // disable this line, because it breaks the code if the square brackets are removed, but it insists that they should be removed
    // eslint-disable-next-line prettier/prettier
    ...mapActions("attributeOverview/mappingCreationOverview", ["setOutputValue"]),
    createNewValueObject(e) {
      var value = {
        id: null,
        attribute: this.attribute,
        content: e.target.value,
        type: "output"
      };
      this.setOutputValue(value);
    }
  }
};
</script>
