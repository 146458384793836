<template>
  <v-card :loading="!showQueryBuilder">
    <v-card-title>
      {{ $t("mapping-creation.rules.title") }}
    </v-card-title>
    <v-card-text>
      <vue-query-builder
        v-if="showQueryBuilder"
        :rules="queryBuilder.rules"
        :maxDepth="queryBuilder.maxDepth"
        :labels="queryBuilder.labels"
        v-model="queryBuilder.query"
      >
        <template v-slot:default="slotProps">
          <vue-query-builder-custom-group
            v-bind="slotProps"
            :query.sync="queryBuilder.query"
          />
        </template>
      </vue-query-builder>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import commonHelper from "@/helpers/commonHelper";

export default {
  name: "RuleBuilder",
  data: vm => ({
    showQueryBuilder: false,
    queryBuilder: {
      query: {},
      maxDepth: 2, // for now we only allow rules with only one group depth (which is a depth of 2). Later we could add parent group relations.
      rules: [
        {
          type: "text",
          id: "input-value",
          label: vm.$t("mapping-creation.rules.input-value")
        }
      ],
      labels: {
        matchType: vm.$t("mapping-creation.rules.match-type"),
        matchTypes: [
          { id: "and", label: vm.$t("generic.and") },
          { id: "or", label: vm.$t("generic.or") }
        ],
        addRule: vm.$t("mapping-creation.rules.add-rule"),
        removeRule: "&times;",
        addGroup: vm.$t("mapping-creation.rules.add-group"),
        removeGroup: "&times;",
        textInputPlaceholder: vm.$t("mapping-creation.rules.input-placeholder")
      }
    }
  }),
  computed: {
    ...mapState(
      "attributeOverview/mappingCreationOverview/ruleBuilderOverview",
      [
        "loading",
        "items",
        "totalItems",
        "itemsPage",
        "itemsPerPage",
        "sortByField",
        "sortDesc",
        "footerProps"
      ]
    ),
    ...mapGetters(
      "attributeOverview/mappingCreationOverview/ruleBuilderOverview",
      ["logicalOperators", "comparisonOperators"]
    ),
    ...mapState("attributeOverview/mappingCreationOverview", ["mapping"]),
    query() {
      return this.queryBuilder.query;
    }
  },
  watch: {
    query(val) {
      // set query builder property in store on changes
      this.setQueryBuilderQuery(val);
    }
  },
  methods: {
    ...mapActions(
      "attributeOverview/mappingCreationOverview/ruleBuilderOverview",
      ["fetchItems", "setQueryBuilderQuery"]
    ),
    getLogicalOperatorsInQueryBuilderFormat() {
      return this.logicalOperators.map(x => {
        return { id: x.id, label: x.name };
      });
    },
    setQueryInQueryBuilderForBackendQuery(query) {
      console.log(JSON.stringify(query));
    }
  },
  mounted() {
    // convert backend query to query builder
    if (this.mapping.query != null) {
      this.setQueryBuilderForQuery(this.mapping.query);
    }

    this.fetchItems().then(() => {
      // get data in right format
      var logicalOperators = this.getLogicalOperatorsInQueryBuilderFormat();
      if (
        commonHelper.isEmpty(logicalOperators) ||
        commonHelper.isEmpty(this.comparisonOperators)
      ) {
        this.showSnack({
          text: this.$t("mapping-creation.errors.no-operators"),
          color: "error"
        });
        return;
      }

      // add input value rule
      this.queryBuilder.rules = [
        {
          type: "text",
          id: "input-value",
          label: this.$t("mapping-creation.rules.input-value"),
          operators: this.comparisonOperators
        }
      ];

      // add operators
      this.queryBuilder.labels.matchTypes = logicalOperators;

      // set query defaults
      this.queryBuilder.query.logicalOperator = logicalOperators[0].id;
      this.queryBuilder.query.children = [];

      // show the query builder after we've set everything up
      this.showQueryBuilder = true;
    });
  }
};
</script>
