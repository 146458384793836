<template>
  <v-dialog
    fullscreen
    transition="dialog-bottom-transition"
    v-model="show"
    hide-overlay
  >
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="cancel()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title v-if="mapping.id">{{
        $t("mapping-creation.title-update")
      }}</v-toolbar-title>
      <v-toolbar-title v-else>{{
        $t("mapping-creation.title")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="save">
          Save
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-row>
        <v-col col="6">
          <RuleBuilder />
        </v-col>
        <v-col cols="6">
          <MappingInputValues />
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-list three-line subheader>
        <v-subheader>{{ $t("mapping-creation.actions") }}</v-subheader>
        <v-list-item>
          <v-list-item-action>
            <MappingOutputValues />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("mapping-creation.output-value")
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              $t("mapping-creation.output-value-description")
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-text-field
              v-model="priority"
              hide-details
              single-line
              type="number"
            />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("mapping-creation.priority")
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              $t("mapping-creation.priority-description")
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-btn @click="dryRun" color="primary">{{
              $t("mapping-creation.dry-run")
            }}</v-btn>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("mapping-creation.dry-run")
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              $t("mapping-creation.dry-run-description")
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import { DialogMixin } from "@tt/vue-components";
import { mapActions, mapState } from "vuex";
import MappingInputValues from "@/components/attribute/mappingCreation/MappingInputValues";
import MappingOutputValues from "@/components/attribute/mappingCreation/MappingOutputValues";
import RuleBuilder from "@/components/attribute/mappingCreation/RuleBuilder";

export default {
  name: "MappingCreationDialog",
  components: {
    MappingInputValues,
    MappingOutputValues,
    RuleBuilder
  },
  mixins: [DialogMixin],
  computed: {
    ...mapState("attributeOverview/mappingCreationOverview", ["mapping"]),
    ...mapState(
      "attributeOverview/mappingCreationOverview/mappingInputValueOverview",
      ["selectedItems"]
    ),
    priority: {
      get() {
        return this.$store.state.attributeOverview.mappingCreationOverview
          .mapping.priority;
      },
      set(value) {
        this.setPriority(value);
      }
    }
  },
  methods: {
    ...mapActions("attributeOverview/mappingCreationOverview", [
      "addMapping",
      "executeDryRun",
      "setPriority",
      "clearMapping",
      "deleteQuery"
    ]),
    ...mapActions(
      "attributeOverview/mappingCreationOverview/mappingInputValueOverview",
      { fetchInputValues: "fetchItems" }
    ),
    ...mapActions("modelDialog", ["closeModelDialog"]),
    ...mapActions("snackbar", ["showSnack"]),
    async dryRun() {
      console.log("dry run");
      var result = await this.executeDryRun(this.selectedItems);
      console.log(JSON.stringify(result));
      // TODO execute dry run for each value, set mapped for values that will be mapped when the mapping is saved
    },
    cancel() {
      this.deleteQuery()
        .then(() => {
          this.closeModelDialog();
        })
        .catch(errorMessage => {
          this.showSnack({ text: errorMessage, color: "error" });
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.addMapping(this.mapping)
          .then(() => {
            this.closeModelDialog();
            this.showSnack({
              text: this.$t("mapping-creation.saved"),
              color: "success"
            });

            this.$refs.form.reset();
          })
          .catch(errorMessage => {
            this.showSnack({ text: errorMessage, color: "error" });
          });
      }
    }
  }
};
</script>
