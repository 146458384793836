<template>
  <v-card>
    <v-toolbar dense flat class="header">
      <v-icon class="pr-3">mdi-tag-text</v-icon>

      <v-toolbar-title>{{
        attribute ? attribute.name : $t("attributes.select_attribute")
      }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-slot:extension>
        <v-tabs grow v-model="tab">
          <v-tabs-slider></v-tabs-slider>
          <v-tab key="Input values">
            <v-badge v-if="totalInputValues" inline :content="totalInputValues">
              {{ $tc("input-values.title", 2) }}
            </v-badge>
            <div v-else>{{ $tc("input-values.title", 2) }}</div>
          </v-tab>
          <v-tab key="Mappings">
            <v-badge v-if="totalMappings" inline :content="totalMappings">
              {{ $tc("mappings.title", 2) }}
            </v-badge>
            <div v-else>
              {{ $tc("mappings.title", 2) }}
            </div>
          </v-tab>
          <v-tab key="IgnoredValues">
            <v-badge
              v-if="totalInputIgnoredValues"
              inline
              :content="totalInputIgnoredValues"
            >
              {{ $t("values.ignored.title") }}
            </v-badge>
            <div v-else>
              {{ $t("values.ignored.title") }}
            </div>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-if="attribute" v-model="tab">
      <v-tab-item key="Input values">
        <InputValues />
      </v-tab-item>
      <v-tab-item key="MappingResults">
        <MappingResults />
      </v-tab-item>
      <v-tab-item key="IgnoredValues">
        <InputValuesIgnored />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import InputValues from "@/components/attribute/InputValues";
import MappingResults from "@/components/attribute/MappingResults";
import InputValuesIgnored from "@/components/attribute/InputValuesIgnored";

export default {
  name: "AttributeInfo",
  components: { InputValues, MappingResults, InputValuesIgnored },
  data: () => ({
    tab: 0
  }),
  computed: {
    ...mapState("attributeOverview", ["attribute"]),
    ...mapGetters("mappingOverview/mappingResultOverview", {
      totalMappings: "totalItems"
    }),
    ...mapGetters("attributeOverview/inputValueOverview", {
      totalInputValues: "totalItems"
    }),
    ...mapGetters("attributeOverview/inputValueIgnoredOverview", {
      totalInputIgnoredValues: "totalItems"
    })
  }
};
</script>
