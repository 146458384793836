<template>
  <v-dialog v-if="value" v-model="show" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("quickmapping-dialog.title") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-on:submit.prevent>
          <p>
            {{ $t("quickmapping-dialog.value-to-map-field") }}:
            {{ value.content }} ({{ value.attribute.name }})
          </p>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="outputValue"
                :label="$t('quickmapping-dialog.value-target-field')"
                :rules="[
                  v => !!v || $t('generic.required-field'),
                  v =>
                    v === null ||
                    v === '' ||
                    v.length >= 1 ||
                    $t('validation.min', { num: 1 }),
                  v =>
                    v === null ||
                    v === '' ||
                    v.length <= 254 ||
                    $t('validation.max', { num: 254 })
                ]"
                counter="255"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="closeModelDialog()">
          {{ $t("form.cancel") }}
        </v-btn>
        <v-btn color="blue darken-1" :loading="loading" text @click="save">
          {{ $t("form.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DialogMixin } from "@tt/vue-components";
import { mapActions, mapState } from "vuex";

export default {
  name: "QuickMappingDialog",
  mixins: [DialogMixin],
  data: () => ({
    loading: false,
    outputValue: null
  }),
  computed: {
    ...mapState("attributeOverview/inputValueOverview", ["value"])
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("quickMappingOverview", ["createQuickMapping"]),
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.createQuickMapping(this.outputValue)
          .then(() => {
            this.closeModelDialog();
            this.showSnack({
              text: this.$t("quickmapping-dialog.saved"),
              color: "success"
            });
            this.loading = false;
            // this.buffer = 0;
            this.$refs.form.reset();
          })
          .catch(ex => {
            console.log(ex);
            this.loading = false;
            let errorMessage = "";
            if (Array.isArray(ex)) {
              ex.forEach(message => (errorMessage += message.message + "\n"));
            } else {
              errorMessage = ex;
            }
            this.showSnack({ text: errorMessage, color: "error" });
          });
      }
    }
  }
};
</script>
