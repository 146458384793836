<template>
  <v-card :loading="loading">
    <v-toolbar dense flat class="header">
      <v-toolbar-title>{{ $t("attribute.overview") }}</v-toolbar-title>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-switch
              v-model="onlyShowValuesToMap"
              color="blue"
              single-line
              hide-details
              class="pr-4"
              @change="searchItems"
            />
          </span>
        </template>
        <span>{{ $t("attributes.only_show_values_to_map") }}</span>
      </v-tooltip>
      <v-text-field
        v-model="attributeSearch"
        append-icon="mdi-magnify"
        :label="$t('form.search')"
        single-line
        flat
        hide-details
        clearable
        style="max-width: 210px"
        @input="searchAttribute"
        @click:clear="clearSearchAttribute"
      />
    </v-toolbar>

    <v-data-table
      :headers="headers"
      v-model="selected"
      :items="items"
      :items-per-page="itemsPerPage"
      :page="itemsPage"
      :server-items-length="totalItems"
      :sort-by="sortByField"
      :sort-desc="sortDesc"
      must-sort
      @update:page="updatePage($event)"
      @update:items-per-page="updateItemsPerPage($event)"
      @update:sort-by="updateSortBy($event)"
      @update:sort-desc="updateSortOrder($event)"
      :footer-props="footerProps"
      item-key="id"
      :loading-text="$t('table.loading', { prop: $t('attribute.title') })"
      :no-data-text="$t('table.no_data', { prop: $t('attribute.title') })"
      single-select
      class="row-pointer"
      @click:row="selectAll"
    >
      <template v-slot:[`item.totalUnmappedInputValues`]="{ item }">
        <v-tooltip right :key="item.value">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              {{ item.totalInputValues }} ({{ item.totalUnmappedInputValues }})
            </span>
          </template>
          <span>{{
            $t("attribute.statistics-alt", {
              total: item.totalInputValues,
              unmapped: item.totalUnmappedInputValues
            })
          }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";

export default {
  name: "Attributes",
  data: vm => ({
    headers: [
      {
        text: vm.$t("attributes.table.attribute"),
        value: "name"
      },
      {
        text: vm.$t("attributes.table.values_to_be_mapped"),
        value: "totalUnmappedInputValues"
      }
    ],
    selected: []
  }),
  computed: {
    ...mapState("attributeOverview/attributeOv", [
      "loading",
      "items",
      "totalItems",
      "itemsPage",
      "itemsPerPage",
      "sortByField",
      "sortDesc",
      "footerProps",
      "totalCounts"
    ]),
    attributeSearch: {
      get() {
        return this.$store.state.attributeOverview.attributeOv.attributeSearch;
      },
      set(value) {
        this.setAttributeSearch(value);
      }
    },
    onlyShowValuesToMap: {
      get() {
        return this.$store.state.attributeOverview.attributeOv
          .onlyShowValuesToMap;
      },
      set(value) {
        this.setOnlyShowValuesToMap(value);
      }
    }
  },
  mounted() {
    let attribute = this.$route.params.attribute;
    if (attribute !== undefined) {
      this.setAttributeId(this.$route.params.attribute);
      this.searchItems();
      this.selectAttribute(attribute);
      this.selected = [
        {
          attribute: attribute
        }
      ];
    } else {
      this.fetchItems();
    }
  },
  methods: {
    ...mapActions("attributeOverview/attributeOv", [
      "fetchItems",
      "setSearch",
      "searchItems",
      "updatePage",
      "updateItemsPerPage",
      "updateSortBy",
      "updateSortOrder",
      "setOnlyShowValuesToMap",
      "setAttributeSearch",
      "setAttributeId",
      "fetchAttributesTotalCounts"
    ]),
    ...mapActions("attributeOverview", ["selectAttribute"]),
    selectAll(item, row) {
      row.select(true);
      this.selectAttribute(item);
    },
    clearSearchAttribute: function() {
      this.attributeSearch = null;
      this.searchItems();
    },
    searchAttribute: _.debounce(function() {
      if (this.attributeSearch != null && this.attributeSearch.length > 1) {
        this.searchItems();
      }
    }, 500)
  }
};
</script>
<style lang="css" scoped>
.row-pointer {
  cursor: pointer;
}
.label {
  display: inline-block;
  margin-bottom: 0rem;
}

.v-tooltip__content {
  background-color: #2196f3 !important;
}
</style>
