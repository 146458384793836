<template>
  <v-card tile :loading="loading">
    <v-card-title>
      {{ $t("mapping-creation.values-to-map") }}
    </v-card-title>
    <v-row class="mr-3 mb-2 mt-2">
      <v-spacer></v-spacer>
      <v-text-field
        v-model="valueSearch"
        append-icon="mdi-magnify"
        :label="$t('form.search')"
        single-line
        flat
        hide-details
        style="max-width: 400px"
        class="pr-4"
        @input="searchItems"
      ></v-text-field>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :page="itemsPage"
      :server-items-length="totalItems"
      :sort-by="sortByField"
      :sort-desc="sortDesc"
      must-sort
      v-model="selectedItems"
      show-select
      @update:page="updatePage($event)"
      @update:items-per-page="updateItemsPerPage($event)"
      @update:sort-by="updateSortBy($event)"
      @update:sort-desc="updateSortOrder($event)"
      :footer-props="footerProps"
      :loading-text="$t('table.loading', { prop: $t('input-values.title') })"
      :no-data-text="$t('table.no_data', { prop: $t('input-values.title') })"
    >
      <template v-slot:[`item.mapped`]="{ item }">
        <VariablePass
          :mappedOption="getMappedOption(item)"
          v-slot="{ mappedOption }"
        >
          <v-icon :color="mappedOption.color">{{ mappedOption.icon }}</v-icon>
        </VariablePass>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VariablePass from "@/components/VariablePass";

export default {
  name: "MappingInputValues",
  components: {
    VariablePass
  },
  data: vm => ({
    headers: [
      {
        text: vm.$t("value.table.value"),
        value: "content"
      },
      {
        text: vm.$t("value.table.is_mapped"),
        value: "mapped",
        sortable: false
      }
    ],
    mappedOptions: [
      {
        title: vm.$t("generic.all"),
        value: null,
        icon: "mdi-all-inclusive",
        color: ""
      },
      {
        title: vm.$t("generic.true"),
        value: true,
        icon: "mdi-checkbox-marked-circle",
        color: "green"
      },
      {
        title: vm.$t("generic.false"),
        value: false,
        icon: "mdi-close-circle",
        color: "red"
      }
    ]
  }),
  computed: {
    ...mapState("attributeOverview", ["attribute"]),
    ...mapState(
      "attributeOverview/mappingCreationOverview/mappingInputValueOverview",
      [
        "loading",
        "items",
        "totalItems",
        "itemsPage",
        "itemsPerPage",
        "sortByField",
        "sortDesc",
        "footerProps"
      ]
    ),
    mappedStatus: {
      get() {
        return this.$store.state.attributeOverview.mappingCreationOverview
          .mappingInputValueOverview.mappedStatus;
      },
      set(value) {
        this.setMappedStatus(value);
      }
    },
    valueSearch: {
      get() {
        return this.$store.state.attributeOverview.mappingCreationOverview
          .mappingInputValueOverview.valueSearch;
      },
      set(value) {
        this.setValueSearch(value);
      }
    },
    selectedItems: {
      get() {
        return this.$store.state.attributeOverview.mappingCreationOverview
          .mappingInputValueOverview.selectedItems;
      },
      set(value) {
        this.setSelectedItems(value);
      }
    }
  },
  methods: {
    ...mapActions("modelDialog", ["showModelDialog"]),
    ...mapActions(
      "attributeOverview/mappingCreationOverview/mappingInputValueOverview",
      [
        "fetchItems",
        "searchItems",
        "updatePage",
        "updateItemsPerPage",
        "updateSortBy",
        "updateSortOrder",
        "setValueSearch",
        "setMappedStatus",
        "setValue",
        "setSelectedItems"
      ]
    ),
    getMappedOption(item) {
      return this.mappedOptions.find(x => x.value == item.mapped);
    }
  },
  mounted() {
    this.fetchItems();
  }
};
</script>
